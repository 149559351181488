.services__container {
  grid-template-columns: repeat(3, 270px);
  justify-content: center;
  column-gap: 1.8rem;
}

.services__content {
  position: relative;
  background-color: var(--container-color);
}

.services__icon {
  display: block;
  font-size: 2rem;
  color: var(--title-color);
  margin-bottom: var(--mb-1);
}

.services__img {
  object-fit: cover;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

.services__title {
  font-size: var(--normal-font-size);
  margin-top: var(--mb-1);
  font-weight: var(--font-medium);
  cursor: pointer;
  color: var(--title-color);
  text-transform: uppercase;
  text-align: center;

}

.services__title:hover .services__title-icon {
  transform: translateX(0.25rem);
}

.services__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}

.services__modal-content {
  width: 1000px;
  height: 80vh;
  overflow-y: auto;

  position: relative;
  background-color: var(--container-color);
  padding: 4.5rem 2.5rem 2.5rem;
  border-radius: 0.5rem;
}

.services__modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
  background-color: white;
}

.services__modal-title,
.services__modal-description {
  text-align: center;
}

.services__modal-title {
  font-size: var(--h2-font-size);
  color: var(--golden-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-2);
  margin-top: var(--mb-3);
}

.services__modal-description {
  font-size: var(--normal-font-size);
  padding: 1 2.5rem;
  margin-bottom: var(--mb-1);
  line-height: var(--mb-1-5);
}

.services__modal-services {
  row-gap: 0.75rem;
}

.services__modal-service {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.services__modal-icon {
  color: var(--title-color);
  font-size: 1.1rem;
}

.services__modal-info {
  font-size: var(--small-font-size);
}

/* Active Modal */
.active-modal {
  opacity: 1;
  visibility: visible;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .services__container {
    grid-template-columns: repeat(1, 300px);
  }
  .services__modal-close {
    top: 0.5rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .services__container {
  }



  .services__modal-description {
    font-size: var(--normal-font-size);
    margin-bottom: var(--mb-0-5);
  }

  .services__icon {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 576px) {
  .services__modal-content {
    padding: 2rem 1.5rem 2rem;
  }

  .services__modal-description {
    padding: 0.25rem;
  }

  .services__modal-description p {
    padding: 0.25rem;
    font-size: var(--small-font-size);
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .services__container {
    grid-template-columns: max-content;
  }

  .services__content {
    padding-right: 4rem;
  }
}