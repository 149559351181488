/* gallery */
.gallery {
  -webkit-column-count: 3 !important;
  -moz-column-count: 3 !important;
  column-count: 3 !important;
  -webkit-column-width: 33%;
  -moz-column-width: 33;
  column-width: 33%;
  padding: 0 12px;
}
.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}
.gallery .pics:hover {
  filter: opacity(0.8);
}
@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2 !important;
    -moz-column-count: 2 !important;
    column-count: 2 !important;
  }
}
@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1 !important;
    -moz-column-count: 1 !important;
    column-count: 1 !important;
    -webkit-column-width: 100%;
    -moz-column-width: 100;
    column-width: 100%;
  }
}
.customImg {
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}
.unsetImg {
  width: 100%;
}
.unsetImg > div {
  position: unset !important;
}





/* gallery - model */
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  transition:opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}
.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.exitIcon {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem !important;
  height: 2rem !important;
  padding: 5px;
  background-color: rgba(0, 0, 0 ,0.4);
  color: white;
  cursor: pointer;
}
.prevIcon {
  position: fixed;
  left: 10px;
  color: white;
  cursor: pointer;
  width: 2em !important;
  height: 2em !important;
}
.nextIcon {
  position: fixed;
  right: 10px;
  color: white;
  cursor: pointer;
  width: 2em !important;
  height: 2em !important;
}

.imgModal {
  object-fit: contain;
  width: 100%;
  max-height: 100vh;
  margin: auto;
}