.portfolio__filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
}

.portfolio__item {
  cursor: pointer;
  color: var(--title-color);
  padding: 0.25rem 0.75rem;
  font-weight: var(--font-medium);
  text-transform: capitalize;
}

.portfolio__item:hover {
  background-color: var(--title-color);
  color: var(--container-color);
}

.portfolio__container {
  grid-template-columns: repeat(2, max-content);
  gap: 3rem;
  justify-content: center;
}

.portfolio__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.portfolio__img {
  width: 500px;
  margin-bottom: var(--mb-1);
}

.portfolio__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  text-align: center;
}

.portfolio__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.portfolio__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.portfolio__button:hover .portfolio__button-icon {
  transform: translateX(0.25rem);
}

/* Active Portfolio */
.active-portfolio {
  background-color: var(--title-color);
  color: var(--container-color);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .portfolio__container {
    gap: 1.25rem;
  }

  .portfolio__card {
    padding: 1rem;
  }

  .portfolio__img {
    margin-bottom: 0.75rem;
  }
}

.portfolio__title {
  margin-bottom: 0.25rem;
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .portfolio__container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 576px) {
  .portfolio__container {
    grid-template-columns: 1fr;
  }

  .portfolio__img {
    width: 100%;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .portfolio__item {
    font-size: var(--small-font-size);
  }

  .portfolio__filters {
    column-gap: 0.25rem;
  }
}